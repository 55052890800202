$(document).ready(function(){
    $('.afs-card-slider .owl-carousel').owlCarousel({
        loop:false,
        stagePadding: 0,
        autoWidth:false,
        margin: 15,
        nav:true,
        responsive:{
            0:{
                items:1
            },
            435:{
                items:2
            },
            645:{
                items:3
            },
            930:{
                items:4
            },
            1140:{
                items:5
            }
        }
    })
});